import React from 'react';
import { ChildDataProps, graphql } from 'react-apollo';
import { RouteComponentProps } from 'react-router';

import { LoadingPage } from '../components/LoadingPage';
import { ErrorPage } from './ErrorPage';
import { ResultsBody } from '../components/ResultsBody';
import {
  searchApps,
  SearchAppsResponse,
  SearchAppsVariables
} from '../graphql/searchApps';
import { SubHeader } from '../components/Header';
import { useI18n } from '../hooks';

type DeveloperAppsProps = {
  developerProfileId: string;
  clientIpAddress?: string;
};

type ChildProps = ChildDataProps<
  DeveloperAppsProps & RouteComponentProps,
  SearchAppsResponse,
  SearchAppsVariables
>;

const DeveloperApps: React.FC<ChildProps> = ({ data }) => {
  const { t } = useI18n();
  const { clockResults, appResults, loading, error } = data;

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage />;
  }

  let developerName: string = '';
  if (appResults && appResults.apps.length) {
    developerName = appResults.apps[0].developer.name;
  } else if (clockResults && clockResults.apps.length) {
    developerName = clockResults.apps[0].developer.name;
  }

  return (
    <React.Fragment>
      <SubHeader>{t`Apps & Clock Faces by ${developerName}`}</SubHeader>
      <ResultsBody
        apps={appResults && appResults.apps}
        showApps={true}
        clocks={clockResults && clockResults.apps}
        showClocks={true}
      />
    </React.Fragment>
  );
};

export default graphql<
  DeveloperAppsProps,
  SearchAppsResponse,
  SearchAppsVariables,
  ChildProps
>(searchApps, {
  options: (props) => ({
    variables: {
      developerProfileId: props.developerProfileId,
      clientIpAddress: props.clientIpAddress
    }
  })
})(DeveloperApps);
